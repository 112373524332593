'use strict';

import React, { useState }  from 'react';
import PropTypes from 'prop-types';

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {ValidateInput, NumberInputCodes} from '../lib/Constants'

const SleepPeriodForm = (props) => {
    // Declare a new state variable, which we'll call "count"
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        let value = e.target.value;
        setValue(value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSave(value.toString());
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h2 className="h4">Sleep Period</h2>
            <div className="d-flex space-x-3 align-items-end">
                <FloatingLabel
                    controlId="sleepPeriod"
                    label="Sleep Period (Minutes)"
                    className="w-100">
                    <Form.Control
                        type="number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        pattern="[0-9]*"
                        placeholder="0"
                        onKeyDown={(e) => ValidateInput(e, NumberInputCodes)}
                        onChange={handleChange}
                        value={value}
                        onWheel={(e) => e.target.blur()}
                    />
                </FloatingLabel>
                <Button
                    size="sm"
                    type="submit"
                    disabled={!value}>
                    Submit
                </Button>
            </div>
        </Form>
    )
}

SleepPeriodForm.propTypes = {
    onSave: PropTypes.func.isRequired
};

export default SleepPeriodForm;