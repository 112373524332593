'use strict';

// Pull in the styles ...
import './main/scss/main.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';

import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import {AppContext} from "@uw-it-sis/lib-react/lib/AppContext";
import App from "@uw-it-sis/lib-react/lib/App";
import history from '@uw-it-sis/lib-react/lib/History';
import {render} from '@uw-it-sis/lib-react/lib/Render';
import AppHeaderDefault from "@uw-it-sis/lib-react/lib/AppHeaderDefault";
import AppFooterDefault from "@uw-it-sis/lib-react/lib/AppFooterDefault";

import SpsConsole from "./main/js/console/SpsConsole";

/**
 * Define the routes.
 */
const routes =
    <Routes>
        <Route path="" element={<SpsConsole />} />
        <Route path=":activeTab" element={<SpsConsole />} />
        <Route path=":activeTab/*" element={<SpsConsole />} />
    </Routes>;

/**
 * Put the AppStore in the React context so that it's available to any class that needs it.
 */
const router = (
    <AppContext.Provider value={{
        appStore: appStore,
        history: history
    }}>
        <HashRouter>
            <App
                name="console"
                title="SPS Console"
                routes={routes}
                header={<AppHeaderDefault enableBannerMessages={false} title="SPS Console" />}
                footer={<AppFooterDefault />}
                showAppNavigation={false} />
        </HashRouter>
    </AppContext.Provider>
);

render(router);