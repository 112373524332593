'use strict';

import appStore from '@uw-it-sis/lib-react/lib/AppStore';

class Api {
    constructor() {
        this.baseUrl = "api";
    }

    /**
     * Title IX Status Poller
     */
    getTixStatus() {
        return appStore.getHttpClient().doGet(`${this.baseUrl}/syncTixStatus/status`, 'getTixStatus');
    }

    pauseTixStatus() {
        return appStore.getHttpClient().doPost(`${this.baseUrl}/syncTixStatus/status/pause`, '', 'pauseTixStatus');
    }

    resumeTixStatus() {
        return appStore.getHttpClient().doPost(`${this.baseUrl}/syncTixStatus/status/resume`, '', 'resumeTixStatus');
    }

    clearTixStatus() {
        return appStore.getHttpClient().doPost(`${this.baseUrl}/syncTixStatus/status/needsAttention`, "false", 'clearTixStatus');
    }

    reprocessTixStatus(minutes) {
        return appStore.getHttpClient().doPost(`${this.baseUrl}/syncTixStatus/status/reprocess`, minutes, 'reprocessTixStatus');
    }

    sleepPeriodTixStatus(minutes) {
        return appStore.getHttpClient().doPost(`${this.baseUrl}/syncTixStatus/status/sleepPeriod`, minutes, 'sleepPeriodTixStatus');
    }

    queueTixStatus(syskey) {
        // syskey - an array of syskeys, e.g. ["1234567", "2345678"]
        return appStore.getHttpClient().doPost(`${this.baseUrl}/syncTixStatus/status/queue`, syskey, 'queueTixStatus');
    }
}

export default new Api();