module.exports.TimeStampFormat = "YYYY-MM-DD HH:mm:ss";

module.exports.ApiDateFormat = "MM/DD/YYYY HH:mm:ss";

module.exports.TimeRange = {
    "Minute(s)": 1,
    "Hour(s)": 60,
    "Day(s)": (60 * 24),
    "Week(s)": (60 * 24 * 7),
    "Month(s)": (60 * 24 * 30)
}

module.exports.MaxTimeRange = (900 * 24 * 60);

module.exports.NumberInputCodes = [0, 8, 9, 17, 27, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 93, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

module.exports.SysKeyInputCodes = [0, 8, 9, 17, 27, 32, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 93, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 188];

module.exports.ValidateInput = (event, allowedCodes) => {
    const charCode = String.fromCharCode(event.which).toLowerCase();
    let keyCode;
    if (event.keyCode !== undefined) {
        // Handle the event with KeyboardEvent.key
        keyCode = event.keyCode;
    } else if (event.which !== undefined) {
        // Handle the event with KeyboardEvent.which
        keyCode = event.which
    }
    // Is the keydown a copy, cut, paste, or select all action
    const isAllowedAction = ( (event.ctrlKey || event.metaKey) && ['a', 'x', 'c', 'v'].includes(charCode));
    const isAllowedCode = allowedCodes.includes(keyCode);
    // If the keydown is an allowed action or allowed key, continue, if not prevent default
    if ( isAllowedCode || isAllowedAction ) {
        return true;
    } else {
        event.preventDefault();
    }
}

module.exports.MockTixStatus =
    {
        "needsAttention": false,
        "needsAttentionErrorLimit": 2,
        "queuedItems": [],
        "poller": {
            "state": "RUNNING",
            "started": 1670606656285,
            "lastSuccessfulIteration": 1670606729604,
            "lastError": null,
            "failedIterationCount": 0,
            "errorMessages": [],
            "sleepPeriod": 600000,
            "config": {
                "autoRecoverPeriod": 300,
                "sleepPeriod": 10000
            }
        },
        "queue": {
            "state": "RUNNING",
            "started": 1670606656271,
            "lastSuccessfulIteration": 1670606656271,
            "lastError": null,
            "failedIterationCount": 0,
            "errorMessages": [],
            "sleepPeriod": 10000,
            "config": {
                "autoRecoverPeriod": 300,
                "sleepPeriod": 10000
            }
        },
        "currentCursorDate": "12/09/2022 08:25:29",
        "reprocessCursorDate": "09/10/2022 09:25:40",
        "processedSyskeysCount": 0
    };