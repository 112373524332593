'use strict';

import React, { useState }  from 'react';
import PropTypes from 'prop-types';

import {MdOutlineSaveAlt} from "react-icons/md";
import {FaPlay} from "react-icons/fa";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {ValidateInput, SysKeyInputCodes} from '../lib/Constants'

const SysKeyForm = (props) => {
    // Declare a new state variable, which we'll call "count"
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        let value = e.target.value;
        setValue(value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSave(value);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h2 className="h4">Syskeys</h2>
            <div className="d-flex space-x-3 align-items-end">
                <FloatingLabel
                    controlId="sysKey"
                    label="SysKeys (comma separated)"
                    className="w-100">
                    <Form.Control
                        as="textarea"
                        style={{ height: '100px' }}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        placeholder="123456789"
                        onChange={handleChange}
                        onKeyDown={(e) => ValidateInput(e, SysKeyInputCodes)}
                        value={value}
                    />
                </FloatingLabel>
                <Button
                    size="sm"
                    type="submit"
                    disabled={!value}>
                    Submit
                </Button>
            </div>
        </Form>
    )
}

SysKeyForm.propTypes = {
    onSave: PropTypes.func.isRequired
};

export default SysKeyForm;