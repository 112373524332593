'use strict';

import React, {Component, useContext} from 'react';
import {observer} from "mobx-react";
import moment from 'moment';
import Loader from "@dethereum/react-loader-advanced";

import {FaCheck, FaSyncAlt, FaTimes, FaPause, FaPlay, FaChevronDown} from "react-icons/fa";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import SimpleState from "@uw-it-sis/lib-react/lib/SimpleState";
import {States} from "@uw-it-sis/lib-react/lib/AppConstants";

import tixStatusStore from './TixStatusStore';
import {TimeStampFormat, ApiDateFormat} from '../lib/Constants';
import ReprocessForm from "./ReprocessForm";
import SleepPeriodForm from "./SleepPeriodForm";
import SysKeyForm from "./SysKeyForm";

function ContextAwareToggle({ children, eventKey, callback }) {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const keypress = (event) => {
        const key = event.key.toLowerCase();
        const code = event.code;
        const keyCode = event.keyCode;
        if (key === 'enter' || key === ' ' || code === "Space" || keyCode === 32 || keyCode === 13 ) {
            decoratedOnClick();
        }
    }

    return (
        <div role="button" tabIndex={0} className="d-flex justify-content-between align-items-center" onClick={decoratedOnClick} onKeyUp={keypress}>
            {children}
        </div>
    );
}

class TixStatus extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.store = tixStatusStore;

        this.onClickReload = this.onClickReload.bind(this);
        this.onClickPause = this.onClickPause.bind(this);
        this.onClickResume = this.onClickResume.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.onReprocess = this.onReprocess.bind(this);
        this.onSleepPeriod = this.onSleepPeriod.bind(this);
        this.buildDoneComponent = this.buildDoneComponent.bind(this);
    }

    componentDidMount() {
        this.store.loadStatus();
    }

    onClickReload = () => this.store.reloadStatus();

    onClickPause = () => this.store.pause();

    onClickResume = () => this.store.resume();

    onClickClear = () => this.store.clearErrors();

    onReprocess = (minutes) => this.store.reprocess(minutes);

    onSleepPeriod = (minutes) => this.store.sleepPeriod(minutes);

    onQueue = (syskey) => this.store.queue(syskey);

    _makeTimestamp(date, isDateTimeStamp = false) {
        if (date) {
            if (isDateTimeStamp) {
                return moment(date, ApiDateFormat).format(TimeStampFormat);
            } else {
                return moment(date).format(TimeStampFormat);
            }
        } else {
            return "Unset";
        }
    }

    buildDoneComponent() {
        let status = this.store.getStatus();

        const listItemClasses = "d-inline-flex justify-content-between align-items-center space-x-5";

        let queuedEvents =
            <ListGroup.Item className={listItemClasses}>
                <strong>Queued Syskeys</strong>
                <div className="space-x-3">
                    <span>None</span>
                    <Badge bg="purple" pill>{status.queuedItems.length}</Badge>
                </div>
            </ListGroup.Item>;
        if (status.queuedItems.length > 0) {
            let queuedList = status.queuedItems.map(function(item, i) {
                return <li key={i}>{item}</li>;
            });
            queuedEvents =
                <ListGroup.Item>
                    <ContextAwareToggle eventKey="queuedEvents">
                        <strong>Queued Syskeys</strong>
                        <div className="space-x-3">
                            <Badge bg="purple" pill>{status.queuedItems.length}</Badge>
                            <FaChevronDown aria-hidden={true} />
                        </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="queuedEvents">
                        <ul className="my-3" style={{columns: 4, WebkitColumns: 4, MozColumns: 4}}>{queuedList}</ul>
                    </Accordion.Collapse>
                </ListGroup.Item>;
        }

        let failureMessagesList = [];

        let failurePollerMessages =
            <ListGroup.Item className={listItemClasses}>
                <strong>Failure Poller Reasons</strong>
                <Badge bg="success" pill>{status.poller.errorMessages.length}</Badge>
            </ListGroup.Item>;
        if (status.poller.errorMessages.length > 0) {
            let failurePollerMessagesList = status.poller.errorMessages.map(function(item, i) {
                return (<li key={i}>{item}</li>);
            });
            failurePollerMessages =
                <ListGroup.Item>
                    <ContextAwareToggle eventKey="failurePollerMessages">
                        <strong>Failure Poller Reasons</strong>
                        <div className="space-x-3">
                            <Badge bg="danger" pill>{status.poller.errorMessages.length}</Badge>
                            <FaChevronDown aria-hidden={true} />
                        </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="failurePollerMessages">
                        <ul className="my-3">{failurePollerMessagesList}</ul>
                    </Accordion.Collapse>
                </ListGroup.Item>;
        }

        let failureQueueMessages =
            <ListGroup.Item className={listItemClasses}>
                <strong>Failure Queue Reasons</strong>
                <Badge bg="success" pill>{status.queue.errorMessages.length}</Badge>
            </ListGroup.Item>;
        if (status.queue.errorMessages.length > 0) {
            let failureQueueMessagesList = status.queue.errorMessages.map(function(item, i) {
                return (<li key={i}>{item}</li>);
            });
            failureQueueMessages =
                <ListGroup.Item>
                    <ContextAwareToggle eventKey="failureQueueMessages">
                        <strong>Failure Queue Reasons</strong>
                        <div className="space-x-3">
                            <Badge bg="danger" pill>{status.queue.errorMessages.length}</Badge>
                            <FaChevronDown aria-hidden={true} />
                        </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="failureQueueMessages">
                        <ul className="my-3">{failureQueueMessagesList}</ul>
                    </Accordion.Collapse>
                </ListGroup.Item>;
        }

        let operationAlert;
        if (this.store.operationData.recentlyOperated) {
            operationAlert = (
                <Alert variant="success" className="d-inline-block">
                    <FaCheck className="me-2" aria-hidden={true} />
                    Completed {this.store.operationData.description} for Title IX Status
                </Alert>
            );
        }

        // Choose a button to render for pause/resume.
        let pauseResumeButton = null;
        if (status.poller.state !== "PAUSED") {
            pauseResumeButton =
                <Button
                    className="text-nowrap d-flex align-items-center"
                    title="Pause processing"
                    onClick={this.onClickPause}>
                    <FaPause aria-hidden={true} className="me-2" /> Pause
                </Button>;
        } else {
            pauseResumeButton =
                <Button
                    className="text-nowrap d-flex align-items-center"
                    title="Resume processing"
                    onClick={this.onClickResume}>
                    <FaPlay aria-hidden={true} className="me-2" /> Resume
                </Button>;
        }

        const loaderSpinner =
            <Spinner
                animation="border"
                role="status">
                <span className="sr-only">Updating Title IX Status...</span>
            </Spinner>;

        return (
            <div>
                <h1>Title IX Status</h1>
                {operationAlert}
                <Loader
                    show={this.store.state === States.pending}
                    message={loaderSpinner}
                    contentBlur={1}
                    disableDefaultStyles>
                    <div className="d-flex space-x-5">
                        <div className="flex-grow-1">
                            <Accordion flush>
                                <ListGroup>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Current Cursor Date</strong>
                                        <span>{this._makeTimestamp(status.currentCursorDate, true)}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Reprocess Cursor Date</strong>
                                        <span>{this._makeTimestamp(status.reprocessCursorDate, true)}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Processed Syskey Count</strong>
                                        <Badge bg="purple" pill>{status.processedSyskeysCount}</Badge>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Needs Attention</strong>
                                        <Badge bg={status.needsAttention ? 'danger':'success'}>{status.needsAttention ? "Needs Attention" : "OK"}</Badge>
                                    </ListGroup.Item>
                                    {queuedEvents}
                                    {/**
                                     POLLER DATA
                                    */}
                                    <ListGroup.Item variant={status.poller.state.toLowerCase() === "running" && parseInt(status.poller.failedIterationCount) === 0 ? "success":"warning"}>
                                        <h2 className="h4 my-1">Poller</h2>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>State</strong>
                                        <span>{status.poller.state}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Last Successful Poller Date</strong>
                                        <span>{this._makeTimestamp(status.poller.lastSuccessfulIteration)}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Poller Sleep Period</strong>
                                        <span>{status.poller.sleepPeriod} ms</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Poller Config Sleep Period</strong>
                                        <span>{status.poller.config.sleepPeriod} ms</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Poller Config Auto Recover</strong>
                                        <span>{status.poller.config.autoRecoverPeriod}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Failed Poller Count</strong>
                                        <Badge bg={parseInt(status.poller.failedIterationCount) > 0 ? 'danger':'success'} pill>{status.poller.failedIterationCount}</Badge>
                                    </ListGroup.Item>
                                    {failurePollerMessages}
                                    {/**
                                     QUEUE DATA
                                     */}
                                    <ListGroup.Item variant={status.queue.state.toLowerCase() === "running" && parseInt(status.queue.failedIterationCount) === 0 ? "success":"warning"}>
                                        <h2 className="h4 my-1">Queue</h2>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>State</strong>
                                        <span>{status.queue.state}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Last Successful Queue Date</strong>
                                        <span>{this._makeTimestamp(status.queue.lastSuccessfulIteration)}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Queue Sleep Period</strong>
                                        <span>{status.queue.sleepPeriod} ms</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Queue Config Sleep Period</strong>
                                        <span>{status.queue.config.sleepPeriod} ms</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Queue Config Auto Recover</strong>
                                        <span>{status.queue.config.autoRecoverPeriod}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={listItemClasses}>
                                        <strong>Failed Queue Count</strong>
                                        <Badge bg={parseInt(status.queue.failedIterationCount) > 0 ? 'danger':'success'} pill>{status.queue.failedIterationCount}</Badge>
                                    </ListGroup.Item>
                                    {failureQueueMessages}
                                </ListGroup>
                            </Accordion>
                        </div>
                        <div className="space-y-5">
                            <ButtonGroup>
                                <Button
                                    className="text-nowrap d-flex align-items-center"
                                    title="Get latest status"
                                    onClick={this.onClickReload}>
                                    <FaSyncAlt aria-hidden={true} className="me-2" /> Update
                                </Button>
                                {pauseResumeButton}
                                <Button
                                    className="text-nowrap d-flex align-items-center"
                                    title="Clear errors"
                                    onClick={this.onClickClear}>
                                    <FaTimes aria-hidden={true} className="me-2" /> Clear
                                </Button>
                            </ButtonGroup>
                            <ReprocessForm onSave={this.onReprocess} />
                            <SleepPeriodForm onSave={this.onSleepPeriod} />
                            <SysKeyForm onSave={this.onQueue} />
                        </div>
                    </div>
                </Loader>
            </div>
        )
    }

    render() {
        // Use 'dots' animation for first time load of course section data
        let pendingComponent = "dots";

        if ( this.store.hasData() ) {
            pendingComponent = this.buildDoneComponent;
        }

        return (
            <SimpleState
                state={this.store.state}
                pendingComponentBuilder={pendingComponent}
                doneComponentBuilder={this.buildDoneComponent}
                name="TixStatus"
            />
        );
    }
}

export default observer(TixStatus);