'use strict';

import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';

import {FaExclamationCircle} from "react-icons/fa";

import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import {TimeRange, MaxTimeRange, ValidateInput, NumberInputCodes} from '../lib/Constants';

const ReprocessForm = (props) => {
    // Declare a new state variable, which we'll call "count"
    const [state, setState] = useState({
        input: "",
        increment: ""
    });
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        let isInvalid = false;
        const inputsNotEmpty = Object.values(state).every(value => value !== "");
        if (inputsNotEmpty) {
            isInvalid = Object.values(state).reduce((a, b)=> a*b, 1) > MaxTimeRange;
        } else {
            isInvalid = false;
        }
        setInvalid(isInvalid);
    }, [state]);

    let options = Object.entries(TimeRange).map(([key, value], index) => {
        return (
            <option value={value} key={index}>{key}</option>
        )
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (invalid) {
            event.stopPropagation();
        } else {
            const minutes = (state.input * state.increment).toString();
            props.onSave(minutes);
        }
    }

    const disableButton = () => {
        const inputsNotEmpty = Object.values(state).every(value => value !== "");
        if (inputsNotEmpty) {
            const underMaxTime = Object.values(state).reduce((a, b)=> a*b, 1) <= MaxTimeRange;

            return !underMaxTime;
        } else {
            return true;
        }
    }

    let invalidTimeRange;
    if (invalid) {
        invalidTimeRange =
            <Alert variant="danger" className="alert-sm d-inline-flex align-items-center mb-0 mt-3">
                <FaExclamationCircle aria-hidden="true" className="me-2 fs-5"/>
                <span>Time range must be less than {MaxTimeRange/(24*60)} days</span>
            </Alert>;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h2 className="h4">Reprocess</h2>
            <div className="d-flex space-x-3 align-items-end">
                <FloatingLabel
                    controlId="reprocess.input"
                    label="Time Input">
                    <Form.Control
                        name="input"
                        type="number"
                        defaultValue={state.input}
                        onChange={handleChange}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        pattern="[0-9]*"
                        placeholder="0"
                        onKeyDown={(e) => ValidateInput(e, NumberInputCodes)}
                        onWheel={(e) => e.target.blur()}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="reprocess.increment"
                    label="Time Increment">
                    <Form.Select
                        name="increment"
                        defaultValue={state.increment}
                        onChange={handleChange}>
                        <option value="">Select time increment</option>
                        {options}
                    </Form.Select>
                </FloatingLabel>
                <Button
                    size="sm"
                    type="submit"
                    disabled={disableButton()}>
                    Submit
                </Button>
            </div>
            {invalidTimeRange}
        </Form>
    );
}

ReprocessForm.propTypes = {
    onSave: PropTypes.func.isRequired
};

export default ReprocessForm;