'use strict';

import { flow, observable, configure, action, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import appStore from '@uw-it-sis/lib-react/lib/AppStore';
import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {sleep} from "@uw-it-sis/lib-react/lib/WaitUtils";

import api from '../lib/Api';
import {MockTixStatus} from '../lib/Constants';

/**
 * Store for Title IX status.
 */
class TixStatusStore {
    // State object
    state = States.initial;

    operationData = {
        description: "",
        recentlyOperated: false
    };

    constructor() {
        makeObservable(this, {
            state: observable,
            operationData: observable,
            setRecentlyOperated: action,
            reloadStatus: action
        });

        this.status = undefined;
        this.exceptionUtils = appStore.getExceptionUtils();
    }

    /*
     * Makes a post request to the given url.
     */
    _sendPost = flow(function* (action, operationDescription, data) {

        this.state = States.pending;

        this.operationData.description = operationDescription;

        try {
            if (action === 'pause') {
                this.status = yield api.pauseTixStatus();
            } else if (action === 'resume') {
                this.status = yield api.resumeTixStatus();
            } else if (action === 'clear') {
                this.status = yield api.clearTixStatus();
            } else if (action === 'reprocess' && data) {
                this.status = yield api.reprocessTixStatus(data);
            } else if (action === 'sleepPeriod' && data) {
                this.status = yield api.sleepPeriodTixStatus(data);
            } else if (action === 'queue' && data) {
                let postData = data.replace(/\s+/g, '').split(",");
                this.status = yield api.queueTixStatus(postData);
            }

            // only set the recently operated flag if the operation succeeded
            this.setRecentlyOperatedTemporarily();
        } catch (error) {
            this.exceptionUtils.handleException(error);
        }

        this.state = States.done;

    }.bind(this));

    setRecentlyOperatedTemporarily = flow (async function* () {

        this.setRecentlyOperated(true);

        yield new Promise(resolve => resolve()).then();

        await sleep(2000);

        this.setRecentlyOperated(false);
    }.bind(this));

    setRecentlyOperated(newValue) {
        this.operationData.recentlyOperated = newValue;
    }

    pause = () => this._sendPost('pause', 'pausing');
    resume = () => this._sendPost('resume', 'resuming');
    clearErrors = () => this._sendPost('clear', 'clearing errors');
    reprocess = (minutes) => this._sendPost('reprocess', 'backs the cursor data up by the number of minutes', minutes);
    sleepPeriod = (minutes) => this._sendPost('sleepPeriod', 'updates the polling sleep between polls period', minutes);
    queue = (syskey) => this._sendPost('queue', 'add a syskey to the processing queue', syskey);

    loadStatus = flow(function* () {
        // Update the aggregate state to pending.
        this.state = States.pending;
        try {
            this.status = yield api.getTixStatus();
            if (appStore.isDebug()) {
                console.log("TixStatusStore: Data load complete.");
            }
            this.state = States.done;
        } catch (error) {
            this.state = States.error;
        }
    }.bind(this));

    reloadStatus() {
        this.operationData.description = "refreshing status";

        this.loadStatus();

        this.setRecentlyOperatedTemporarily();
    }

    getStatus = () => { return this.status }

    hasData = () => { return this.status !== undefined }
}

export default new TixStatusStore();