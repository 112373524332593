'use strict';

import React from 'react';

import AppConsole from '@uw-it-sis/lib-react/lib/AppConsole';

import TixStatus from "../tix/TixStatus";

const SpsConsole = () => {
    const tabPanels = {
        "tix": {
            title: 'Title IX Status',
            component: <TixStatus />
        },
    }

    return (
        <AppConsole tabPanels={tabPanels} />
    )
}

export default SpsConsole;